
.received-message > div {
    background-color: white;
    display: inline-block;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid lightgray;
}

.received-message {
    text-align: left;
}

.my-message > div {
    background-color: #2c36f4;
    display: inline-block;
    padding: 10px;
    margin-bottom: 10px;
    color:white;
    border-radius: 5px;
}

.my-message {
    text-align: right;
}

#messages {
    max-height: 525px;
    padding: 20px;
    overflow-y: auto;
    min-height: 525px;
}

#input-box {
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
    padding-left: 20px;
    padding-right: 20px;
}

#message-input {
    flex: 1;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

#chat {
    background-color: white;
    padding: 12px !important;
    margin-top: 20px;
    box-shadow: 1px 1px 1px 1px black;
}

#send-button {
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
}