
.rangeFilters {
    padding-top: 20px;
    background: #eaeaea;
    padding-bottom: 20px;
    margin-top: 15px;
  }

 
.res-cur {
  background: #f4f4f44a;
  color: green;
  padding: 2px;
  border-radius: 8px;
  padding-left: 8px;
  font-size: 12px;
  padding-right: 8px;
  border: 1px dashed lightgray;
  margin-top: 4px;
  display: inline-block;
  margin-bottom: 4px;
}

td {
  border-bottom: 1px solid #ededed !important;
}
.res-mov {
  background: #f4f4f44a;
  color: rgb(179, 25, 25);
  padding: 2px;
  border-radius: 8px;
  margin-top: 4px;
  padding-left: 8px;
  font-size: 12px;
  border: 1px dashed lightgray;
  padding-right: 8px;
  display: inline-block;
  margin-bottom: 4px;
}

.res-evc {
  background: #f4f4f44a;
  color: lightgray;
  padding: 2px;
  border-radius: 8px;
  border: 1px dashed lightgray;
  margin-top: -4px;
  padding-left: 8px;
  font-size: 12px;
  padding-right: 8px;
  display: inline-block;
  margin-bottom: 4px;
}

.res-lea {
  background: #f4f4f44a;
  color: rgb(16, 84, 255);
  padding: 2px;
  border-radius: 8px;
  border: 1px dashed lightgray;
  margin-top: 4px;
  padding-left: 8px;
  font-size: 12px;
  padding-right: 8px;
  display: inline-block;
  margin-bottom: 4px;
}


.rangeFilters {
    padding-top: 20px;
    background: #eaeaea;
    padding-bottom: 20px;
    margin-top: 15px;
  }


.m-group {
    margin-left: 12px;
    margin-right: 12px;
    border: 1px solid lightgray;
    border-radius: 8px;
    display: flex;
    padding-top: 10px;
    max-width: 200px;
    min-height: 90px;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    cursor: pointer;
  }
  
  .m-group.active {
    box-shadow: inset 0px 11px 0px 0px #0058ff;
  }
  
  .m-group.disabled {
    opacity: 0.2;
    cursor: default !important;
  }
  
  .m-group label {
    padding-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .m-group div {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 10px;
  }

  .mr-2 {
    margin-right:10px;
  }

.my-table-dark th {
    background-color: #f8f8f8;
    font-size: 10px;
    text-align: left;
    padding-top: 40px;
}

.table-striped td {
    font-size: 13px !important;
}

.btn-link {
    font-size: 13px;
    color: #28237a !important;
}

.modal-dialog {
    max-width: 90%;
  }
  
  .inline-block {
    display: inline-block !important;
    width: auto;
  }

  .status-badge {
    background: rgb(87, 87, 87);
    padding: 4px 10px;
    border-radius: 10px;
    font-size: 10px;
    display: inline-block;
    color: white;
  }