.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.hidden {
  display: none;
}

#ringtone-devices,
#speaker-devices {
  font-size: 12px;
  padding-left: 4px;
  padding-right: 4px;
}

.modal {
  background-color: #00000099;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#pnlJoined {
  padding: 10px;
}

.room-info {
  box-shadow: 1px 1px 1px 1px black;
  background: white;
  padding: 20px;
}

canvas {
  width: 100% !important;
}

.text-left {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.close-button {
  background-color: white;
  border-radius: 4px;
  border: 1px solid lightgray;
}

.block {
  display: block !important;
}
.tab-borders {
  border-left: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #e1e1e1;
}

select,
input:not(.rti--input),
textarea {
  border: 1px solid rgb(193 193 193) !important;
  border-radius: 10px !important;
}

table tr:last-of-type td {
  border-bottom: 0px !important;
}

.table-aligned-left td {
  text-align: left;
}

.table-aligned-left th {
  text-align: left;
}

.lato-thin {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lato-regular,
body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.nav-link {
  color: #675959;
}

.nav-link.active {
  color: #5c30d8 !important;
}

.lato-regular-italic {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", sans-serif;
  font-weight: 900;
  font-style: italic;
}

tbody {
  border-top: none !important;
}

.tag-container > div {
  background-color: unset !important;
  border: unset !important;
  margin-top: 4px;
  outline: none !important;
}

.gray-form-container {
  width: 100%;
  padding: 10px;
  background: #27272714;
  border-radius: 3px;
  box-shadow: 3px 4px 10px #0000001f;
}

.card {
  border: 0px;
  box-shadow: 1px 1px 19px #00000026;
}

.tag-container > div:focus {
  outline: none !important;
}

th,
label {
  color: #63656d;
  font-size: 15px;
  font-style: normal;
  font-weight: 550;
  font-family: "Lato", sans-serif;
}

.loader {
  display: none;
}

.loading .loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #00000073;
  z-index: 1111;
  text-align: center;
  display: block !important;
}

.loader img {
  display: inline-block;
  width: 300px;
  height: auto;
  margin-top: 25vh;
}

.loading .root-container {
  filter: blur(2px) !important;
}

.site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgray;
  margin-left: -20px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #15212d;
  color: white;
  margin-right: -20px;
}

.table-striped th {
  background-color: #f8f8f8;
    font-size: 10px;
    text-align: left;
    padding-top: 40px;
}

.table-striped td {
  padding-top: 14px;
  padding-bottom: 14px;
}


.brand-name {
  font-weight: 300;
  font-size: 24px;
}

.table {
  margin-bottom: 0px !important;
}


.no-padding .modal-body {
  padding: 0px !important;
  
}
.lead-container {
  padding-left: 12px;
  padding-right: 12px;
}

.no-padding .modal-body .lead-container {
  padding-top: 0px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.no-padding .modal-body .card-header {
  border-radius: 0px !important;
}

.no-padding .modal-body .card.mt-4 {
  margin-top: 0px !important;
}

.no-padding #communication {
  margin-bottom: 0px !important;
}

.btn-link {
  padding: 0px !important;
}

.mg1.active {
  background-color: #d3d3d33d !important;
}


.appointment-table td {
  vertical-align: middle;
}