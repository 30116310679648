

.text-right {
    text-align: right;
}

.existing-phone-record {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    padding: 2px;
    border-radius: 4px;
    border: 1px dotted #babbff;
}

.save-changes-warning {
    margin-top: -10px;
    padding: 10px;
    border-bottom: 2px solid orangered;
    background: orange;
    display: flex;
    align-items: center;
    margin:5px;
}

.table-light {
    border: 1px solid lightgray;
}

.table-light td, .table-light th {
    background-color: white !important;
}

.ml-2 {
    margin-left: 5px;
}

.mr-2 {
    margin-left: 5px;
}

.mr-4 {
    margin-left: 10px;
}

.fa-btn {
    border: 0px;
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
}


  .twilio-to{
    background: #c1d2d8;
    display: inline-block;
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
    border: 1px dotted #7e7e8e;
    margin-bottom: 4px;
  }

  .lead-container {
    /* background:#cbcbcb; */
    padding-bottom: 10px;
    padding-top: 10px;
  }

  .note-record {
    vertical-align: middle;
  }

  #left-menu-container {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #0a0a0ac7;
    justify-content: space-between;
    flex-direction: row;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    left: 0;
  }

  #left-menu-container a {
    display: flex;
    width: 150px;
    justify-content: center;
    height: 38px;
    text-align: center;
    background: white;
    align-items: center;
    text-decoration: none;
    font-size: 13px;
    border-radius: 10px;;
  }

  #lead-info-start {
    width:'100%';
    margin-top: 60px
  }

  .modal #lead-info-start {
    margin-top: 0px !important;
  }

  .modal-content {
    margin-top: 75px;
  }