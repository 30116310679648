
.btn-xs{
    padding: 4px;
    font-size: 11px;
}

#ringtone-devices, #speaker-devices {
    width: 100%;
}

#get-devices {
    text-align: center;
}

#get-devices {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 10px;
}

.pl-2 {
    padding-left: 8px;
}

.pr-2 {
    padding-right: 8px;
}

#ddlStatus {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
}




  #outgoing-call {
    background: white;
    box-shadow: 1px 1px 5px #a9a3a3db;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
  }
  #incoming-call h2 {
    font-size: 20px;
    font-weight: 400;
  }
  
  #incoming-number {
    color: #460760 !important;
    text-shadow: none !important;
  }
  
  #incoming-call {
    background: linear-gradient(45deg, #bf57ff, #3dffe9);
    box-shadow: 1px 1px 5px #a9a3a3db;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
  }
  
  #incoming-call .instructions {
    color: white;
    font-weight: 500;
    font-size: 20px;
    text-shadow: 1px 1px 1px black;
  }
  
  .make-a-call-label {
    font-size: 20px;
    font-weight: 400;
  }
  
  #output-selection {
    background: white;
    box-shadow: 1px 1px 5px #a9a3a3db;
    padding: 10px;
  }
  
  #get-devices {
    background-color: rgb(68 68 68);
    color: white;
    border: 0px;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 12px;
    margin-top: 4px;
  }
  #button-call {
    background: green;
    color: white;
    border: 0px;
    padding: 3px;
    margin-left: 10px;
    padding-left: 8px;
    padding-right: 8px;
  }
  
  
.do-mute {
    background: purple;
    color: white;
  }

  .sidePhone header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sidePhone h3 {
    margin-bottom: 0px !important;
  }


  .sidePhone {
    display: block;
    border-right: 1px solid #bdbdbd;
    max-width: 250px;
    background:#f7f7f7;
    font-size: 12px;
    padding: 10px;
}

