.appointment-form label {
    display: block;
}

.appointment-form-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.call-button-container button {
    background-color: white;
}

.call-button-container button:hover {
    background-color: #dadada !important;
    color: black;
}

.table-v-middle td {
    vertical-align: middle;
}